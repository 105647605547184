import React from 'react'
import classNames from 'classnames'
import Player from '@vimeo/player'

import Analytics from '../../util/analytics'

import './Video.scss'

interface Props {
  src: string
  id: string
  className?: any
}

export default class Video extends React.Component<Props> {
  public state = {
    videoPlayed: false,
    video25Played: false,
    video50Played: false,
    video75Played: false,
    video100Played: false
  }

  private player: any
  private videoTitle: string

  public componentDidMount() {
    const options = {
      url: this.props.src,
      loop: false
    }

    this.player = new Player(this.props.id, options)

    this.player
      .getVideoTitle()
      .then((title: string) => {
        this.videoTitle = title
      })
      .catch(() => {
        this.videoTitle = this.props.src
      })

    this.player.on('play', () => {
      if (!this.state.videoPlayed) {
        Analytics.videoPlayed(this.videoTitle)
      }
    })

    this.player.on('timeupdate', (data: any) => {
      if (data.percent >= 1) {
        if (!this.state.video100Played) {
          this.setState({ video100Played: true })
          Analytics.videoPlayed(this.videoTitle, 100)
        }
      } else if (data.percent >= 0.75) {
        if (!this.state.video75Played) {
          this.setState({ video75Played: true })
          Analytics.videoPlayed(this.videoTitle, 75)
        }
      } else if (data.percent >= 0.5) {
        if (!this.state.video50Played) {
          this.setState({ video50Played: true })
          Analytics.videoPlayed(this.videoTitle, 50)
        }
      } else if (data.percent >= 0.25) {
        if (!this.state.video25Played) {
          this.setState({ video25Played: true })
          Analytics.videoPlayed(this.videoTitle, 25)
        }
      }
    })
  }

  public render() {
    return (
      <div
        className={classNames({
          [this.props.className]: !!this.props.className,
          video: true
        })}
      >
        <div className="video__container">
          <div className="video__iframe" id={this.props.id} />
        </div>
      </div>
    )
  }
}
